/**
 * 公共ng请求方法
 * 使用：request.get/post/send
 *
 * @param arg_url 请求地址
 * @param arg_param 请求参数key-value
 * @param arg_data post body内容
 * @param arg_needLogin 是否需要登陆
 * @param arg_filter ng $http里的option
 * @returns promise.then(successCallback(rs), failCallback(err))
 *          rs: xhrResponse.data
 *          err: {
 *              type: 1/-1/-2/-3/-4,（1：业务逻辑错误，-1：没有响应code，-2：请求错误，-3：请求超时，-4：取消请求）
 *              data: xhrResponse
 *          }
 *
 * err.type > 0，业务逻辑错误或业务逻辑非默认情况; type < 0, 请求错误，或接口响应数据格式不符合预期
 *      （后面定义 type 请参照该定义）
 */
!function () {
    RequestFactory.$inject = ['$http', '$q'];
    angular.module('app.core').factory('request', RequestFactory);

    function RequestFactory($http, $q) {
        return {
            get: function (arg_url, arg_param, arg_needLogin, arg_filter) {
                arg_param = this.processParams(arg_param, {needLogin: arg_needLogin});

                var config = angular.extend({
                    params: arg_param
                }, arg_filter || {});
                return $http.get(arg_url, config).then(responseHandler, httpErrorHandler);
            },
            post: function (arg_url, arg_param, arg_data, arg_needLogin, arg_filter) {
                arg_param = this.processParams(arg_param, {needLogin: arg_needLogin});

                var config = angular.extend({
                    params: arg_param
                }, arg_filter || {});

                DSSX.util.isInBlackList(arg_url) && (arg_data = {});
                return $http.post(arg_url, arg_data, config).then(responseHandler, httpErrorHandler);
            },
            send: function (arg_option) {
                /* TODO: 加上 needLogin 选项 */

                arg_option.method === 'POST' && DSSX.util.isInBlackList(arg_option.url) && (arg_option.data = {});
                return $http(arg_option).then(responseHandler, httpErrorHandler);
            },
            getToken: function () {
                return DSSX.util.getToken();
            },
            processParams: function (arg_params, option) {
                if (!option) {
                    return arg_params;
                }

                if (angular.isObject(arg_params)) {
                    option.needLogin && (arg_params.token = this.getToken());
                } else {
                    /* TODO: 处理不是 object 的其他 ng 允许的格式 */
                    arg_params = option.needLogin ? {token: this.getToken()} : null;
                }

                return arg_params;
            },
        }

        function responseHandler(arg_response) {
            var deferred = $q.defer();

            if (!arg_response || !arg_response.data || angular.isUndefined(arg_response.data.code)) {
                deferred.reject({
                    // type === -1 请求正常，接口没有返回code
                    type: -1,
                    data: arg_response
                });
                return deferred.promise;
            }

            var code = DSSX.util.safe(arg_response, 'data.code', 0);
            if (code === 0) {
                deferred.resolve(arg_response.data);
            } else if (code === 301) {
                DSSX.util.handle301(DSSX.util.safe(arg_response, 'data.data', ''));
                deferred.reject({
                    // type === 1301 登录信息过期
                    type: 1301,
                    data: arg_response
                });
            } else {
                deferred.reject({
                    // type === 1 请求正常 业务逻辑错误
                    type: 1,
                    data: arg_response
                });
            }
            return deferred.promise;
        }

        function httpErrorHandler(error) {
            var errType = -2;

            do {
                if (!error) {
                    break;
                }

                // err type, 102: http 拦截器 response reject
                if (error.type === 102) {
                    return $q.reject(error);
                }

                if (error.xhrStatus === 'timeout') {
                    errType = -3;
                    break;
                }

                if (error.xhrStatus === 'abort') {
                    errType = -4;
                    break;
                }
            } while (0);

            return $q.reject({
                type: errType,
                data: error
            });
        }
    }
}();