/**
 * Created by zhengyf on 2018/6/1.
 */

!function () {

    var
        util = DSSX.util,
        TIME_INTERVAL = 1000,
        USER_INFO, // 用户信息
        SYSTEM_MODE, // 系统模式
        EXAM_TYPE, // 考试类型, 'default', 'xkz'
        EXAM_INFO, // 考试信息
        EXAM_ID, // 考试id
        EXAM_FINISH_TIME, // 考试结束时间
        EXAM_REMIND_TIME, // 提示考试剩余时间
        REMIND_TIME_TIP, // 剩余时间提示模板
        EXAM_FINISH_TIP, // 考试结束提示模板
        THEORY_EXAM_BTN, // 理论考试按钮文本
        THEORY_EXAM_URL, // 理论考试链接
        remindTimeIntervalId,
        finishIntervalId,
        refreshTimeIntervalId,
        isOpenedRemindTimeTip = false,
        isOpenedExamFinishTip = false,
        remindTipIndex,
        overTipIndex
    ;

    DSSX.loadHandler.New().onload = init;

    function init() {
        console.log('[exam-mode.js] init: ');
        initVM();
        initExamModeConfig();
        initTipTemplate();
        roleJudge();
    }

    function initVM() {
        window.DSSX = window.DSSX || {};
        window.DSSX.exam = window.DSSX.exam || {};
        window.DSSX.exam.openExamFinishTip = openExamFinishTip;
    }

    function initExamModeConfig() {
        USER_INFO = window.DSSX.uinfo || {};
        EXAM_INFO = USER_INFO.examStatusInfo || {};

        var appProp = window.DSSX.appProp || {};
        var theoryExamInfo = EXAM_INFO.theoryExamInfo || {};

        EXAM_FINISH_TIME = EXAM_INFO.trainingFinishTime || 0;
        EXAM_REMIND_TIME = EXAM_INFO.remindTimeBeforeFinish || 0;
        EXAM_ID = EXAM_INFO.examId || '';
        SYSTEM_MODE = appProp.sysMode || 'teach';
        EXAM_TYPE = appProp.examType || 'default'

        // 根据考试类型设置对应的考试配置
        if (EXAM_TYPE === 'xkz') {
            THEORY_EXAM_BTN = '确定'
            THEORY_EXAM_URL = EXAM_TYPE === 'xkz'? '/': theoryExamInfo.url;
        } else {
            THEORY_EXAM_BTN = theoryExamInfo.btnText || '我知道了';
            THEORY_EXAM_URL = 'CLOSE';
        }
    }

    // 角色判断
    function roleJudge() {

        if (DSSX.attr.isPreviewMode) {
            console.log('[exam-mode.js] 预览模式无法启动考试功能');
            return;
        }

        // 教学模式 不启动考试功能
        if (SYSTEM_MODE === 'teach') {
            console.log('[exam-mode.js] 教学模式：开始');
            return;
        }

        // 考试模式 教师访问 不启动考试功能
        if (USER_INFO.trainingRole === 'teacher') {
            console.log('[exam-mode.js] 考试模式：教师角色不启用考试逻辑');
            return;
        }

        if (!EXAM_INFO) {
            console.log('[exam-mode.js] 考试模式：没有考试信息');
            return;
        }

        if (EXAM_INFO && !USER_INFO.trainingRole) {
            console.log('[exam-mode.js] 考试模式：实操结束，请前往参加理论考试');
            return;
        }

        // 考试模式 学生访问 启动考试功能
        console.log('[exam-mode.js] 考试模式');

        clearRelateExamLS();
        tryStartExamMode();
        initWindowOnFocus();
    }

    function clearRelateExamLS() {
        var
            overInfo = getTipOverFlag('exam-mode-tip-over'),
            remindInfo = getTipOverFlag('exam-mode-tip-remind')
        ;

        if (remindInfo && (remindInfo.uid !== USER_INFO.uid || remindInfo.examId !== EXAM_ID)) {
            localStorage.removeItem('exam-mode-tip-remind');
        }

        if (overInfo && (overInfo.uid !== USER_INFO.uid || overInfo.examId !== EXAM_ID)) {
            localStorage.removeItem('exam-mode-tip-over');
        }

    }

    // 启动考试模式
    function tryStartExamMode() {
        remindTimeIntervalId = setInterval(tryStartRemindTimeTipTimer, TIME_INTERVAL);
        finishIntervalId = setInterval(tryStartExamFinishTipTimer, TIME_INTERVAL);
        refreshTimeIntervalId = setInterval(refreshRemainTime, 10000);

    }

    // 初始化提示模板
    function initTipTemplate() {

        var EXAM_REMARK_TPL_DEFAULT = '';

        var EXAM_REMARK_TPL = EXAM_TYPE === 'xkz' ? '' : EXAM_REMARK_TPL_DEFAULT;

        REMIND_TIME_TIP = '<div class="dssx-exam-mode-dialog">' +
            '<div class="dssx-exam-mode-tip-left"><span>!</span></div>' +
            '<div class="dssx-exam-mode-tip-right">' +
            '<h3 class="dssx-exam-mode-tip">温馨提示</h3>' +
            '<p class="dssx-exam-mode-text">还有<span class="dssx-exam-mode-remain-time" style="color: #F52552;">' + EXAM_REMIND_TIME / (60 * 1000) + '</span>分钟就结束考试了哦~请抓紧时间作答</p>' +
            EXAM_REMARK_TPL +
            '</div>' +
            '</div>';

        EXAM_FINISH_TIP = '<div class="dssx-exam-mode-dialog">' +
            '<div class="dssx-exam-mode-tip-left"><span>!</span></div>' +
            '<div class="dssx-exam-mode-tip-right">' +
            '<h3 class="dssx-exam-mode-tip">温馨提示</h3>' +
            '<p class="dssx-exam-mode-text">考试已经结束了哦~已经为您自动提交试卷</p>' +
            EXAM_REMARK_TPL +
            '</div>' +
            '</div>';

    }

    // 剩余时间提醒定时器
    function tryStartRemindTimeTipTimer() {
        var
            curTime = util.getServerCurrentTime().getTime(),
            tipOver = getTipOverFlag('exam-mode-tip-remind')
        ;

        // 已经提醒过
        if (tipOver) {
            clearInterval(remindTimeIntervalId);
            clearInterval(refreshTimeIntervalId);
            return;
        }

        if (isOpenedRemindTimeTip) {
            return;
        }

        // console.log('[exam-mode.js] 准备提醒剩余考试时间');

        // 第一次提醒
        if (curTime + EXAM_REMIND_TIME >= EXAM_FINISH_TIME) {
            console.log('[exam-mode.js] 成功提醒考试剩余时间');
            isOpenedRemindTimeTip = true;
            openRemindTimeTip();
        }
    }

    // 考试结束提醒定时器
    function tryStartExamFinishTipTimer() {
        var
            curTime = util.getServerCurrentTime().getTime(),
            tipOver = getTipOverFlag('exam-mode-tip-over')
        ;

        // 已经提醒过
        if (tipOver) {
            clearInterval(finishIntervalId);
            return;
        }

        if (isOpenedExamFinishTip) {
            return;
        }

        // console.log('[exam-mode.js] 准备提醒考试结束');

        // 第一次提醒
        if (curTime >= EXAM_FINISH_TIME) {
            console.log('[exam-mode.js] 成功提醒考试结束');
            isOpenedExamFinishTip = true;
            clearInterval(remindTimeIntervalId); // 这里是防止提前交卷 弹出结束提醒后 又弹出剩余时间提醒
            clearInterval(refreshTimeIntervalId); // 这里是防止提前交卷 弹出结束提醒后 又弹出剩余时间提醒
            openExamFinishTip();
        }
    }

    function refreshRemainTime() {
        var
            curTime = util.getServerCurrentTime().getTime(),
            remainTime = Math.ceil((EXAM_FINISH_TIME - curTime) / (60 * 1000)),
            remainTimeDom = $('.dssx-exam-mode-dialog .dssx-exam-mode-remain-time')
        ;

        remainTimeDom && remainTimeDom.text(remainTime);
    }

    // 打开剩余时间提示
    function openRemindTimeTip() {
        var data = {
            uid: USER_INFO.uid,
            examId: EXAM_ID
        };

        remindTipIndex = layer.open({
            title: false,
            type: 1,
            closeBtn: 0,
            content: REMIND_TIME_TIP,
            skin: 'dssx-exam-mode-layer-style',
            btn: ['我知道了'],
            success: function () {
                refreshRemainTime(); // 更新一下时间
            },
            yes: function (index) {
                window.localStorage['exam-mode-tip-remind'] = JSON.stringify(data);
                layer.close(index);
            }
        })
    }

    // 打开考试结束提示
    function openExamFinishTip() {
        var data = {
            uid: USER_INFO.uid,
            examId: EXAM_ID
        };

        overTipIndex = layer.open({
            title: false,
            type: 1,
            closeBtn: 0,
            content: EXAM_FINISH_TIP,
            skin: 'dssx-exam-mode-layer-style',
            btn: [THEORY_EXAM_BTN],
            yes: function (index) {
                window.localStorage['exam-mode-tip-over'] = JSON.stringify(data);
                layer.close(index);
                if(THEORY_EXAM_URL === 'CLOSE') {
                    window.close();
                } else {
                    location.href = THEORY_EXAM_URL;
                }
            }
        })
    }

    // 获得提示是否已经有过提示标记
    function getTipOverFlag(key) {
        var data;
        try {

            if (!window.localStorage[key]) {
                return null;
            }

            data = JSON.parse(window.localStorage[key]);
        } catch (error) {
            data = null;
        }

        return data;
    }

    function initWindowOnFocus() {
        window.onfocus = function () {
            console.log('[exam-mode.js] on focus');
            var
                overInfo = getTipOverFlag('exam-mode-tip-over'),
                remindInfo = getTipOverFlag('exam-mode-tip-remind')
            ;

            if (remindInfo) {
                layer.close(remindTipIndex);
                clearInterval(remindTimeIntervalId);
                clearInterval(refreshTimeIntervalId);
            }

            if (overInfo) {
                layer.close(overTipIndex);
                clearInterval(finishIntervalId);
            }
        }
    }

}();
