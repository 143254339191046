;(function () {

    UploaderConfig.$inject = ['request'];
    angular
        .module('app.core')
        .factory('UploaderConfig', UploaderConfig);

    function UploaderConfig(request) {

        // 获得文件上传地址
        function getUploadUrl() {
            return DSSX.host.protocol + '//' + DSSX.host.fs + '/usr/api/multiPartUpload';
        }

        // 文件存在检测地址
        function fileCheckUrl() {
            return DSSX.host.protocol + '//' + DSSX.host.fs + '/usr/api/fileStatus';
        }

        function sameCheckFn(msg, resolve, reject) {
            console.log('this is sameCheck function received (msg, resolve, reject)', msg, resolve, reject);
            var cmds = {
                files: [{
                    name: msg.name || msg.fObj.name,
                    sha1: msg.sha1,
                    md5: msg.md5,
                    size: msg.size
                }],
                token: DSSX.util.getToken()
            };
            cmds.files = angular.toJson(cmds.files);
            var self = this;
            request.get(fileCheckUrl(), cmds, true, {}).then(function (data) {
                console.log('this is sameCheck success, received data: ', data);
                var dataTmp = data.data;
                var uploadObj = DSSX.util.safe(dataTmp, 'uploads.0', {});
                switch (uploadObj.status) {
                    case 'success':
                        uploadObj.code = 0;
                        self.onSuccess(msg.fObj, uploadObj);
                        reject();
                        break;
                    case 'upload':
                        var pTmp = {
                            uploadId: uploadObj.uploadId,
                            readStart: DSSX.util.safe(uploadObj, 'detail.index', 0)
                        };
                        resolve(pTmp);
                        break;
                }
            }, function (err) {
                console.log('this is sameCheck err, received err: ', err);
                self.onError(msg.fObj, err);
                reject();
            })
        }

        return {
            getConfig: function () {
                var workAdd = '/vendors.encrypt.min.js';
                if(workAdd.indexOf('/vendors') !== 0) {
                    // 如果不是以 /vendors 开头，说明可能构建cdn的时候被修改了路径，需要去除首位斜杠
                    workAdd = workAdd.slice(1);
                }
                return {
                    workAdd: workAdd,
                    multiple: true,
                    autoUpload: true,
                    uploadKey: 'default',
                    uploadOption: {
                        srv: getUploadUrl(),
                        params: {
                            pub: 1,
                            token: DSSX.util.getToken()
                        }
                    },
                    chunkSize: DSSX.util.safe(DSSX.appProp, 'cdn.partSize', 2097152),
                    sameCheck: sameCheckFn,
                    onProgress: function (fObj, data) {
                        console.log('this is instance onProgress');
                    },
                    onSuccess: function (fObj, data) {
                        console.log('this is instance onSuccess');
                    },
                    onError: function (fObj, data) {
                        console.log('this is instance onError');
                    }
                }
            }
        };

    }

})();