!function () {
    previewModeFactory.$inject = ['$q', 'godMode'];
    angular.module('app.core')
        .factory('previewModeHttpInterceptor', previewModeFactory);

    function previewModeFactory($q, godMode) {
        return {
            request: function (config) {
                return config;
            },
            requestError: function (rejection) {
                return $q.reject(rejection);
            },
            response: function (response) {
                var code = NaN;
                if (response && response.data && response.data.code) {
                    code = response.data.code;
                }
                if (code === 403 || code === 413) {
                    godMode.msg(code === 403 ? '预览模式只能查看，不能进行修改操作。' : '无权限访问');

                    // err type, 102: http 拦截器 response reject
                    return $q.reject({type: 102, data: response});
                }
                return response;
            },
            responseError: function (rejection) {
                return $q.reject(rejection);
            }
        };
    }
}();