/**
 * godMode
 * 转接 DSSX.godMode
 */
!function () {
    angular.module('app.core')
        .factory('godMode', function () {
            if (DSSX.godMode) {
                return DSSX.godMode
            }
            throw new Error('[godMode.service] DSSX.godMode not found');
        });
}();